/* ./src/index.css */
@tailwind base;
@tailwind components;
@tailwind utilities;

@import url("https://stackpath.bootstrapcdn.com/font-awesome/4.7.0/css/font-awesome.min.css");

@font-face {
  font-family: 'Poppins';
  src: local('Poppins'), url(assets/fonts/Poppins-Regular.ttf) format('truetype');
}

@font-face {
  font-family: 'Cocogoose';
  src: local('Cocogoose'), url(assets/fonts/CocogoosePro-Regular.otf) format('opentype');
}

@font-face {
  font-family: 'Lato';
  src: local('Lato'), url(assets/fonts/Lato-Regular.ttf) format('truetype');
}

body {
  margin: 0;
  /* font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif; */
  font-family: 'Lato';
  /* font-family: 'Poppins'; */
  /* font-family: 'Cocogoose'; */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.react-multi-carousel-track {
  padding-bottom: 20px !important;
}

.thumbnails {
  justify-content: center;
}

.react-responsive-modal-modal {
  min-width: 800px !important;
}

@media only screen and (max-width: 768px) {
  /* For mobile phones: */
  .react-responsive-modal-modal {
    min-width: auto !important;
  }  
}

.slick-prev {
  left: 3px !important;
  z-index: 10;
}

.slick-next {
  right: 3px !important;
  z-index: 10;
}